<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col>
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('fertilizerConfig.fertilizer_type')"
                    label-for="email"
                    >
                    <v-select name="fertilizer_type"
                    v-model="search.fertilizer_type"
                    label="text"
                    :options= fertilizerTypeList
                    />
                    </b-form-group>
                    </b-col>
                    <b-col >
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerConfig.fertilizer_type_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <div class="status_wrapper status_active" v-if="data.item.status == 1" title="active">
                                                <b-badge variant="success">Active</b-badge>
                                            </div>
                                            <div class="status_wrapper status_inactive" v-else title="inactive">
                                                <b-badge variant="dark">Inactive</b-badge>
                                            </div>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Fertilizer Type Modify" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Click to Deactive" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Active" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { fertilizerTypeList, fertilizerTypeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                fertilizer_type: 0
            }
        }
    },
    computed: {
        fertilizerTypeList: function () {
           return this.$store.state.SeedsFertilizer.commonObj.fertilizerTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('fertilizerConfig.fertilizer_type_entry') : this.$t('fertilizerConfig.fertilizer_type_modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.fertilizer_type'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fertilizer_type_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fertilizer_type' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(seedFertilizerServiceBaseUrl, fertilizerTypeToggleStatus, item, 'seeds_fertilizer', 'fertilizerTypeList')
        },
        loadData () {
            var fertilizerTypeSearch = ''
            if (this.search.fertilizer_type) {
                fertilizerTypeSearch = this.search.fertilizer_type.value
            }
            const params = Object.assign({}, this.search, { fertilizer_type: fertilizerTypeSearch, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, fertilizerTypeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
